<template>
    <div id="newsListPage">
      <Empty
        v-if="detail.totalCount === 0"
        emptyImg="icon-empty-3"
        tip="お知らせがありません"
      ></Empty>
      <van-list
        v-model="detail.loading"
        :finished="detail.finished"
        loading-text="ローディング..."
        @load="onLoad"
        v-else
      >
        <ul class="list">
          <li class="list-item" :class="{active: item.state === 2 }" v-for="(item,index) in detail.list" :key="index">
            <div class="date">{{ item.sendTime }}</div>
            <div class="content" @click="jumpTo(item.id,item.activityId,item)">
              <div class="left">
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="desrc">クリックして確認</div>
              </div>
              <div class="right">
                <van-icon name="arrow" />
              </div>
            </div>
          </li>
        </ul>
      </van-list>
    </div>
</template>

<script>
import { Empty } from '@/components';
export default {
  name: 'NewsList',
  components: {
    Empty
  },
  data () {
    return {
      type: '',
      api: '',
      detail: {
        loading: false,
        finished: false,
        pageNo: 1,
        pageSize: 20,
        totalCount: '',
        list: []
      }
    };
  },
  created () {
  },
  mounted () {
    const { params: { type } } = this.$route;
    this.type = type * 1;
    this.init();
  },
  watch: {
  },
  methods: {

    /* 获取消息列表信息
      1.系统消息
      2.订阅购买通知
      3.优惠券提醒
    */
    onLoad () {
      let { detail, type } = this;
      const { pageSize, pageNo } = detail;
      this.$http.get('mine/messageByType', {
        params: {
          type,
          pageSize,
          pageNo
        }
      }).then((res) => {
        let { isHasNext, nextPage, result, totalCount } = res.data || {};
        detail.finished = !isHasNext;
        detail.loading = false;
        detail.pageNo = nextPage;
        detail.totalCount = totalCount;
        detail.list.push(...result);
        this.detail = detail;
      });
    },

    init () {
      const { type, $route: { meta } } = this;
      const info = ({
        1: {
          title: 'サービス情報',
          api: ''
        },
        2: {
          title: 'メッセージ',
          api: ''
        },
        3: {
          title: 'クーボンのお知らせ',
          api: ''
        }
      })[type] || {};
      meta.title = info.title;
      // 清除数据
      this.detail = {
        pageNo: 1,
        list: [],
        totalCount: '',
        pageSize: 20,
        finished: false,
        loading: false
      };
    },

    jumpTo (id, activityId, item) {
      const { $router, $http, type } = this;

      $http.get('mine/click/message', {
        params: {
          id
        }
      }).then((res) => {
        item.state = 1;
      });

      // if (activityId === 0) {
      //   $router.push({
      //     name: 'MemberIndex'
      //   });
      // }
      if (type === 1) {
        $router.push({ name: 'NewsDetail', query: { itemObj: item } });
      } else if (type === 3) {
        $router.push({ name: 'CouponList' });
      } else if (type === 2) {
        if (item.twoType === 6) {
          item.receiveState ? $router.push({ name: 'MemberIndex' }) : $router.push({ name: 'ActivityIndex', query: { activityId, id: item.id } });
        } else if (item.twoType === 1) {
          $router.push({ name: 'PurchaseList' });
        } else if (item.twoType === 5) {
          $router.push({ name: 'MemberIndex' });
        }
      }
    }
  }
};
</script>

<style scoped lang="less">
  #newsListPage {
    .van-list {
      padding: 28px;

      .list {
        .list-item {
          .date {
            margin-bottom: 24px;
            font-size: 22px;
            line-height: 30px;
            text-align: center;
            opacity: 0.5;
          }

          .content {
            display: flex;
            align-items: flex-start;
            padding: 28px;
            font-size: 28px;
            background-color: #2d2e2e;
            border-radius: 24px;

            .left {
              flex: 1;

              .title,
              .desrc {
                // .multi-line-omit();
              }

              .title {
                line-height: 38px;

                &:not(:last-child) {
                  margin-bottom: 24px;
                }
              }

              .desrc {
                font-size: 12px;
                color: #FFFFFF;
                opacity: 0.8;
              }
            }
          }

          .right {
            flex-shrink: 0;
            margin-left: 16px;
          }

          &.active {
            .content {
              .left {
                .title {
                  &::before {
                    position: relative;
                    top: 8px;
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    vertical-align: top;
                    content: '';
                    background-color: #ed544f;
                    border-radius: 50%;
                  }
                }
              }
            }
          }

          &:not(:last-child) {
            margin-bottom: 28px;
          }
        }
      }
    }
  }
</style>